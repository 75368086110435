
import { defineComponent, PropType } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

import { TrackInformationCourierI } from '@/core/types/Delivery.types'

export default defineComponent({
    name: 'courier-information',
    components: {
        SLoader,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        isFailed: {
            type: Boolean,
            default: false,
        },
        isLoaded: {
            type: Boolean,
            default: true,
        },
        isDeliveryCompleted: {
            type: Boolean,
            default: false,
        },
        courier: {
            type: Object as PropType<TrackInformationCourierI>,
            default: null,
        },
        addressFrom: {
            type: String,
            default: null,
        },
        addressTo: {
            type: String,
            default: null,
        },
    },
})
