
import { defineComponent, onBeforeUnmount, onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'

import CourierInformation from '@/modules/Tracking/components/CourierInformation/index.vue'
import { DeliveryRealisation } from '@/core/realisations/Delivery.realisation'

const YANDEX_API_KEY = 'f54e428c-22e6-427d-8f09-b2a41f6588ba'
const YANDEX_API_LANG = 'ru_RU'

const DEFAULT_MAP_ZOOM = 15
const DEFAULT_MAP_CENTER = [43.25391893213578, 76.92523545812777]

const INTERVAL_DELAY = 30000

import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        CourierInformation,
    },
    setup() {
        useTitle('Отслеживание курьера')

        const route = useRoute()
        const deliveryAPI = new DeliveryRealisation()

        const order_id = Number(route.params.order_id)

        let map_instance: any = null
        let interval_id: any = null
        let multi_route: any = null
        let courier_placemark: any = null

        const state = reactive({
            is_loading: false,
            is_failed: false,
            is_loaded: false,
            is_route_drawed: false,
            is_courier_mark_setted: false,
            is_finish_mark_setted: false,
            is_delivery_finished: false,
            //
            latitude_from: null as number | null,
            longitude_from: null as number | null,
            latitude_to: null as number | null,
            longitude_to: null as number | null,
            address_from: null as string | null,
            address_to: null as string | null,
            //
            courier: {
                name: null as string | null,
                phone: null as string | null,
                latitude: null as number | null,
                longitude: null as number | null,
                transport: null as string | null,
                // walker || ?
            },
        })

        const drawRoute = (coordinates: number[][]) => {
            if (!map_instance || state.is_route_drawed) return

            // @ts-ignore
            multi_route = new window.ymaps.multiRouter.MultiRoute(
                {
                    referencePoints: coordinates,
                },
                {
                    wayPointVisible: false,
                    boundsAutoApply: true,
                    balloonLayout: null,
                }
            )

            multi_route.model.events.add('requestsuccess', () => {
                const routes = multi_route.getRoutes()
                const routes_length = routes.getLength()

                const active_route = multi_route.getActiveRoute()
                const active_route_index = active_route.properties.get('index')

                for (let i = 0; i < routes_length; i++) {
                    const route = routes.get(i)
                    const route_index = route.properties.get('index')

                    if (route_index !== active_route_index) {
                        route.destroy()
                    }
                }
            })

            map_instance.geoObjects.add(multi_route)
            state.is_route_drawed = true
        }

        const updateRoute = (coordinates: number[][]) => {
            multi_route.model.setReferencePoints(coordinates)
        }

        const createCourierPlacemark = (coordinates: number[]) => {
            if (state.is_courier_mark_setted) return

            // @ts-ignore
            courier_placemark = new window.ymaps.Placemark(
                coordinates,
                {},
                {
                    preset: 'islands#blueDeliveryCircleIcon',
                    iconColor: '#8BC514',
                    zIndex: 2,
                }
            )

            map_instance.geoObjects.add(courier_placemark)
            state.is_courier_mark_setted = true
        }

        const updateCourierPlacemark = (coordinates: number[]) => {
            courier_placemark.geometry.setCoordinates(coordinates)
        }

        const createFinishPlacemark = (coordinates: number[]) => {
            if (state.is_finish_mark_setted) return

            // @ts-ignore
            const placemark = new window.ymaps.Placemark(
                coordinates,
                {},
                {
                    preset: 'islands#blueGovernmentCircleIcon',
                    iconColor: '#8BC514',
                    zIndex: 1,
                }
            )

            map_instance.geoObjects.add(placemark)
            state.is_finish_mark_setted = true
        }

        const loadTrackInformation = async (): Promise<void> => {
            try {
                state.is_loading = true

                const response = await deliveryAPI.getTrackInformation(order_id)

                state.latitude_from = Number(response.data.latitude_from)
                state.longitude_from = Number(response.data.longitude_from)

                state.latitude_to = Number(response.data.latitude_to)
                state.longitude_to = Number(response.data.longitude_to)

                state.address_from = response.data.address_from
                state.address_to = response.data.address_to

                state.courier.name = response.data.courier.name
                state.courier.phone = response.data.courier.phone
                state.courier.latitude = Number(response.data.courier.latitude)
                state.courier.longitude = Number(response.data.courier.longitude)
                state.courier.transport = response.data.courier.transport

                state.is_failed = false
                state.is_delivery_finished = false
                state.is_loaded = true
            } catch (error) {
                console.error(error)
                state.is_delivery_finished = true
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const intervalHandler = async () => {
            await loadTrackInformation()

            if (state.is_failed || state.is_delivery_finished) {
                clearInterval(interval_id)
                return
            }

            // const from = [state.latitude_from as number, state.longitude_from as number]
            const to = [state.latitude_to as number, state.longitude_to as number]

            const courier_coordinates = [
                state.courier.latitude as number,
                state.courier.longitude as number,
            ]

            if (!state.is_route_drawed) {
                drawRoute([courier_coordinates, to])
            } else {
                updateRoute([courier_coordinates, to])
            }

            if (!state.is_courier_mark_setted) {
                createCourierPlacemark(courier_coordinates)
            } else {
                updateCourierPlacemark(courier_coordinates)
            }

            if (!state.is_finish_mark_setted) createFinishPlacemark(to)
        }

        const createInterval = () => {
            intervalHandler()
            interval_id = setInterval(intervalHandler, INTERVAL_DELAY)
        }

        const createMap = async (): Promise<any> => {
            // @ts-ignore
            const map: any = new window.ymaps.Map('tracking-map', {
                center: DEFAULT_MAP_CENTER,
                zoom: DEFAULT_MAP_ZOOM,
                controls: [],
            })

            map_instance = map
            createInterval()
        }

        const onCoreLoaded = () => {
            try {
                // @ts-ignore
                window.ymaps.ready(createMap)
            } catch (error) {
                console.error(error)
            }
        }

        const loadMapCore = async () => {
            return new Promise<void>((resolve, reject) => {
                try {
                    const script = document.createElement('script')

                    script.onerror = () => {
                        reject('Map script error')
                    }

                    script.id = 'ymaps'
                    script.src = `https://enterprise.api-maps.yandex.ru/2.1?apikey=${YANDEX_API_KEY}&lang=${YANDEX_API_LANG}`
                    script.async = true

                    script.onload = onCoreLoaded

                    document.head.append(script)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        }

        const loadMap = async () => {
            try {
                const script = document.head.querySelector('script#ymaps')

                if (!script) {
                    await loadMapCore()
                } else {
                    createMap()
                }
            } catch (error) {
                console.error(error)
            }
        }

        onMounted(loadMap)

        onBeforeUnmount(() => {
            clearInterval(interval_id)
        })

        return { state }
    },
})
